define("ember-composable-helpers/helpers/map-by", ["exports", "@ember/component/helper", "@ember/object", "@ember/utils"], function (_exports, _helper, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mapBy = mapBy;
  function mapBy(_ref) {
    let [byPath, array] = _ref;
    if ((0, _utils.isEmpty)(byPath)) {
      return [];
    }
    if (!array) {
      array = [];
    }
    return array.map(item => (0, _object.get)(item, byPath));
  }
  var _default = (0, _helper.helper)(mapBy);
  _exports.default = _default;
});