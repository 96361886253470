define("@nycplanning/ember/components/deprecated/icons/rectangle", ["exports", "@ember/component", "@ember/object", "@nycplanning/ember/templates/components/deprecated/icons/rectangle"], function (_exports, _component, _object, _rectangle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    init() {
      this._super(...arguments);
    },
    tagName: 'svg',
    classNames: ['legend-icon-layer', 'rectangle'],
    attributeBindings: ['height', 'width', 'viewBox', 'preserveAspectRatio'],
    layout: _rectangle.default,
    svgOptions: (0, _object.computed)('options', function () {
      const defaultOptions = {
        stroke: 'SteelBlue',
        fill: 'rgba(70, 130, 180, 0.5)',
        'stroke-linejoin': 'round'
      };
      const options = this.get('options'); // options should be an object

      return Object.assign(defaultOptions, options);
    }),
    height: 10,
    width: 17,
    viewBox: '0 0 17 10',
    preserveAspectRatio: 'xMinYMid',
    didInsertElement() {
      const svgPath = this.element.querySelector('path');
      const svgOptions = this.get('svgOptions');
      Object.entries(svgOptions).forEach(_ref => {
        let [attr, value] = _ref;
        svgPath.setAttribute(attr, value);
      });
    }
  });
  _exports.default = _default;
});