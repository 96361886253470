define("@nycplanning/ember/components/labs-ui/layer-groups-container", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive  --}}
  
  {{!-- NOTE: The setNumberMenuItems() function is called when the component is clicked, since {{did-update}} will not rerun whenever the contents or attributes change. For {{did-update}} to rerun whenever a value changes, the value would have to be passed as a parameter to the modifier. Calling setNumberMenuItems() on {{did-insert}} will not work. --}}
  
  {{!-- TODO: Refactor this component so that if it's passed an array of Layer Groups, those are passed to the contextual component, which would allow us to use {{did-update}} to watch for changes that need to call setNumberMenuItems(). --}}
  
  <div
    id="{{this.elementId}}"
    class="layer-groups-container {{if isOpen "open"}} {{if this.numberMenuItems "has-active-layer-groups"}}"
    {{did-insert this.setDefaultIsOpen}}
    {{action this.setNumberMenuItems preventDefault=false}}
  >
    <div {{action "toggleLayerGroupsContainer"}}
      class="layer-groups-container-title {{unless this.open "closed"}} layer-groups-container--{{dasherize @title}}"
      role="button">
      {{@title}}
      {{#if this.numberMenuItems}}
        <span class="badge bg-a11y-yellow">{{this.numberMenuItems}}</span>
      {{/if}}
      {{#if (and this.mapIsLoading this.open this.numberMenuItems)}}
        <FaIcon
          @icon="spinner"
          class="fa-spin medium-gray"
        />
      {{/if}}
    </div>
    <div class="layer-groups-container-content">
      {{yield (hash layer-group-toggle=(component "labs-ui/layer-group-toggle"))}}
    </div>
  </div>
  
  */
  {
    "id": "Hd37YuEk",
    "block": "[[[1,\"\\n\"],[1,\"\\n\"],[1,\"\\n\"],[11,0],[16,1,[29,[[30,0,[\"elementId\"]]]]],[16,0,[29,[\"layer-groups-container \",[52,[33,1],\"open\"],\" \",[52,[30,0,[\"numberMenuItems\"]],\"has-active-layer-groups\"]]]],[4,[38,2],[[30,0,[\"setDefaultIsOpen\"]]],null],[4,[38,3],[[30,0],[30,0,[\"setNumberMenuItems\"]]],[[\"preventDefault\"],[false]]],[12],[1,\"\\n  \"],[11,0],[16,0,[29,[\"layer-groups-container-title \",[52,[51,[30,0,[\"open\"]]],\"closed\"],\" layer-groups-container--\",[28,[37,5],[[30,1]],null]]]],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"toggleLayerGroupsContainer\"],null],[12],[1,\"\\n    \"],[1,[30,1]],[1,\"\\n\"],[41,[30,0,[\"numberMenuItems\"]],[[[1,\"      \"],[10,1],[14,0,\"badge bg-a11y-yellow\"],[12],[1,[30,0,[\"numberMenuItems\"]]],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,6],[[30,0,[\"mapIsLoading\"]],[30,0,[\"open\"]],[30,0,[\"numberMenuItems\"]]],null],[[[1,\"      \"],[8,[39,7],[[24,0,\"fa-spin medium-gray\"]],[[\"@icon\"],[\"spinner\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"layer-groups-container-content\"],[12],[1,\"\\n    \"],[18,2,[[28,[37,9],null,[[\"layer-group-toggle\"],[[50,\"labs-ui/layer-group-toggle\",0,null,null]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@title\",\"&default\"],false,[\"if\",\"isOpen\",\"did-insert\",\"action\",\"unless\",\"dasherize\",\"and\",\"fa-icon\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/layer-groups-container.hbs",
    "isStrictMode": false
  });
  let LayerGroupsContainerComponent = (_class = class LayerGroupsContainerComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", (0, _internals.guidFor)(this));
      _defineProperty(this, "mapIsLoading", false);
      _initializerDefineProperty(this, "isOpen", _descriptor, this);
      _initializerDefineProperty(this, "numberMenuItems", _descriptor2, this);
    }
    get title() {
      return this.args.title || '';
    }
    get open() {
      return this.args.open;
    }
    setDefaultIsOpen() {
      this.isOpen = this.open;
    }
    setNumberMenuItems() {
      const groups = document.getElementById(this.elementId).getElementsByClassName('active');
      this.numberMenuItems = groups.length;
    }
    toggleLayerGroupsContainer() {
      this.isOpen = !this.isOpen;
      this.setNumberMenuItems();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isOpen", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "numberMenuItems", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setDefaultIsOpen", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultIsOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNumberMenuItems", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setNumberMenuItems"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleLayerGroupsContainer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleLayerGroupsContainer"), _class.prototype)), _class);
  _exports.default = LayerGroupsContainerComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LayerGroupsContainerComponent);
});