define("@nycplanning/ember/components/needs-async", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield (hash
    taskInstance=this.needs
    loading=(component "needs-async-state" state="isRunning" taskInstance=this.needs)
    loaded=(component "needs-async-state" state="value" taskInstance=this.needs)
    error=(component "needs-async-state" state="error" taskInstance=this.needs) 
  )}}
  
  */
  {
    "id": "Yrl7ZfKk",
    "block": "[[[18,1,[[28,[37,1],null,[[\"taskInstance\",\"loading\",\"loaded\",\"error\"],[[30,0,[\"needs\"]],[50,\"needs-async-state\",0,null,[[\"state\",\"taskInstance\"],[\"isRunning\",[30,0,[\"needs\"]]]]],[50,\"needs-async-state\",0,null,[[\"state\",\"taskInstance\"],[\"value\",[30,0,[\"needs\"]]]]],[50,\"needs-async-state\",0,null,[[\"state\",\"taskInstance\"],[\"error\",[30,0,[\"needs\"]]]]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@nycplanning/ember/components/needs-async.hbs",
    "isStrictMode": false
  });
  /**
    Usage:
  
    ```hbs
      <NeedsAsync @needs=(find-record 'user' '1') as |States|>
        <States.loading>
          <!-- render a loading state here -->
        </States.loading>
        <States.loaded as |user|>
          <!-- render whatever needs 'user' here -->
        </States.loaded>
        <States.error as |error|>
          <!-- render an error state here optionally using 'error' -->
        </States.error>
      </NeedsAsync>
    ```
  
    @class NeedsAsync
    @yield {Hash} States
    @yield {TaskInstance} States.taskInstance an Ember Concurrency TaskInstance
    @yield {Component} States.loading a component that renders its block if the task instance is still loading
    @yield {Component} States.loaded a component that renders its block and yields the task value once the task is done loading
    @yield {Component} States.error a component that renders its block and yields an error if the tak encountered an error
  */
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    needs: null
  }));
  _exports.default = _default;
});