define("@nycplanning/ember/utils/carto", ["exports", "fetch", "rsvp", "@nycplanning/ember/configuration"], function (_exports, _fetch, _rsvp, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.buildSqlUrl = void 0;
  const cartoUsername = (0, _configuration.default)()['carto-username'] || 'planninglabs';
  const cartoDomain = `${cartoUsername}.carto.com`;
  const buildTemplate = function (cartoResponse, type) {
    // eslint-disable-line
    const {
      layergroupid,
      cdn_url
    } = cartoResponse; // eslint-disable-line
    const {
      subdomains
    } = cdn_url.templates.https;

    // choose a subdomain at random
    const subdomain = subdomains[Math.floor(Math.random() * subdomains.length)];
    return `${cdn_url.templates.https.url.replace('{s}', subdomain)}/${cartoUsername}/api/v1/map/${layergroupid}/{z}/{x}/{y}.${type}`;
  };
  const buildSqlUrl = function (cleanedQuery) {
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
    // eslint-disable-line
    return `https://${cartoDomain}/api/v2/sql?q=${cleanedQuery}&format=${type}`;
  };
  _exports.buildSqlUrl = buildSqlUrl;
  const carto = {
    SQL(query) {
      let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'json';
      const cleanedQuery = query.replace('\n', '');
      const url = buildSqlUrl(cleanedQuery, type);
      return (0, _fetch.default)(url).then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Not found');
      }).then(d => {
        // eslint-disable-line
        return type === 'json' ? d.rows : d;
      });
    },
    getVectorTileTemplate(sourceLayers) {
      const CartoCSS = '#layer { polygon-fill: #FFF; }';
      const layers = sourceLayers.map(sourceLayer => {
        const {
          id,
          sql
        } = sourceLayer;
        return {
          id,
          type: 'mapnik',
          options: {
            cartocss_version: '2.3.0',
            cartocss: CartoCSS,
            sql
          }
        };
      });
      const params = {
        version: '1.3.0',
        layers
      };
      return new _rsvp.Promise((resolve, reject) => {
        (0, _fetch.default)(`https://${cartoDomain}/api/v1/map`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(params)
        }).catch(err => reject(err)).then(response => response.json()).then(json => {
          resolve(buildTemplate(json, 'mvt'));
        });
      });
    }
  };
  var _default = carto;
  _exports.default = _default;
});