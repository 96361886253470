define("@nycplanning/ember/components/labs-ui/legend-icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="legend-icon">
    <span class={{this.iconType}}>
      {{!-- TODO:
        Refactor to use `@iconType` instead of `this.iconType`, which is used here
        because we expect the string 'fa-icon' from labs-layers-api and we change
        it to 'fa-layers'. This string substitution is unnecessary but possibly
        involves changes to 3 repos (this one, style guide, labs-layers-api).
       --}}
  
      {{#each @icon.layers as |icon-layer|}}
  
        {{#if (eq @icon.type "line")}}
          <LabsUi::Icons::Line @options={{icon-layer}} />
        {{/if}}
  
        {{#if (eq @icon.type "rectangle")}}
          <LabsUi::Icons::rectangle @options={{icon-layer}} />
        {{/if}}
  
        {{#if (eq @icon.type "fa-icon")}}
          <LabsUi::Icons::fa-icon @options={{icon-layer}} />
        {{/if}}
  
      {{/each}}
    </span>
  </span>
  
  */
  {
    "id": "NrJ+E60X",
    "block": "[[[10,1],[14,0,\"legend-icon\"],[12],[1,\"\\n  \"],[10,1],[15,0,[30,0,[\"iconType\"]]],[12],[1,\"\\n\"],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"layers\"]]],null]],null],null,[[[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"type\"]],\"line\"],null],[[[1,\"        \"],[8,[39,4],null,[[\"@options\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"type\"]],\"rectangle\"],null],[[[1,\"        \"],[8,[39,5],null,[[\"@options\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,3],[[30,1,[\"type\"]],\"fa-icon\"],null],[[[1,\"        \"],[8,[39,6],null,[[\"@options\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@icon\",\"icon-layer\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"labs-ui/icons/line\",\"labs-ui/icons/rectangle\",\"labs-ui/icons/fa-icon\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/legend-icon.hbs",
    "isStrictMode": false
  });
  class LegendIconComponent extends _component2.default {
    get iconType() {
      const type = this.args.iconType;
      return type === 'fa-icon' ? 'fa-layers' : type;
    }
  }
  _exports.default = LegendIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LegendIconComponent);
});