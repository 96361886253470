define("ember-mapbox-gl/-private/mapbox-loader", ["exports", "@ember/runloop", "@ember/object", "rsvp"], function (_exports, _runloop, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class MapboxLoaderCancelledError extends Error {}
  class MapboxSupportError extends Error {
    constructor() {
      super(...arguments);
      _defineProperty(this, "isMapboxSupportError", true);
    }
  }
  class MapboxError extends Error {
    constructor(ev) {
      super(ev.error?.message ?? 'unknown mapbox error');
      this.event = ev;
    }
  }
  var _default = _object.default.extend({
    map: null,
    error: null,
    MapboxGl: null,
    isLoaded: false,
    _accessToken: null,
    _mapOptions: null,
    _extOnMapLoaded: null,
    _isCancelled: false,
    _isLoading: false,
    load(accessToken, options, onMapLoaded) {
      if (this.isLoaded || this._isLoading || this._isCancelled) {
        return;
      }
      this._isLoading = true;
      this._accessToken = accessToken;
      this._mapOptions = options;
      this._extOnMapLoaded = onMapLoaded;
      emberAutoImportDynamic("mapbox-gl").then((0, _runloop.bind)(this, this._onModule)).then((0, _runloop.bind)(this, this._onMapLoaded)).then((0, _runloop.bind)(this, this._onComplete)).catch((0, _runloop.bind)(this, this._onError));
    },
    cancel() {
      this._isCancelled = true;
      if (this.map !== null) {
        // some map users may be late doing cleanup (seen with mapbox-draw-gl),
        // so don't remove the map until the next tick
        (0, _runloop.next)(this.map, this.map.remove);
      }
    },
    _onModule(MapboxGl) {
      if (this._isCancelled) {
        throw new MapboxLoaderCancelledError();
      }
      this.MapboxGl = MapboxGl.default;
      this.MapboxGl.accessToken = this._accessToken;
      if (!this.MapboxGl.supported()) {
        throw new MapboxSupportError('mapbox-gl not supported in current browser');
      }
      const map = this.map = new this.MapboxGl.Map(this._mapOptions);
      return new _rsvp.Promise((resolve, reject) => {
        const listeners = {
          onLoad() {
            map.off('load', listeners.onLoad);
            map.off('error', listeners.onError);
            resolve();
          },
          onError(ev) {
            map.off('load', listeners.onLoad);
            map.off('error', listeners.onError);
            reject(new MapboxError(ev));
          }
        };
        map.on('load', listeners.onLoad);
        map.on('error', listeners.onError);
      });
    },
    _onMapLoaded() {
      if (this._isCancelled) {
        throw new MapboxLoaderCancelledError();
      }
      if (typeof this._extOnMapLoaded === 'function') {
        return this._extOnMapLoaded(this.map);
      }
      return null;
    },
    _onComplete() {
      this._isLoading = false;
      if (this._isCancelled) {
        return;
      }
      (0, _object.set)(this, 'isLoaded', true);
    },
    _onError(err) {
      this._isLoading = false;
      if (err instanceof MapboxLoaderCancelledError) {
        return;
      }
      if (this._isCancelled) {
        console.error(err); // eslint-disable-line no-console
        return;
      }
      (0, _object.set)(this, 'error', err);
    }
  });
  _exports.default = _default;
});