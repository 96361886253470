define("@nycplanning/ember/components/labs-ui/legend-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="legend-item">
    <div class="grid-x">
      <div class="cell auto">
        <LabsUi::LegendIcon
          @icon={{@item.icon}}
        />
        {{@item.label}}
      </div>
      <div class="cell shrink">
        {{#if @item.tooltip}}
          <LabsUi::icon-tooltip
            @tip={{@item.tooltip}}
            @side="left"
            @fixedWidth={{true}}
          />
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "r548nAvO",
    "block": "[[[10,0],[14,0,\"legend-item\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"grid-x\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"cell auto\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@icon\"],[[30,1,[\"icon\"]]]],null],[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"cell shrink\"],[12],[1,\"\\n\"],[41,[30,1,[\"tooltip\"]],[[[1,\"        \"],[8,[39,2],null,[[\"@tip\",\"@side\",\"@fixedWidth\"],[[30,1,[\"tooltip\"]],\"left\",true]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@item\"],false,[\"labs-ui/legend-icon\",\"if\",\"labs-ui/icon-tooltip\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/legend-item.hbs",
    "isStrictMode": false
  });
  class LegendItemComponent extends _component2.default {
    /**
    This component expects to be passed an item object that with an particular
    format (as formatted in labs-layer-api):
     {
      "label":"Legend Item Label",
      "tooltip": "This is the legend item tooltip.",
      "icon": {
        "type": "line",
        "layers": [
          {
            "fill":"Green"
          },
          {
            "fill":"none",
            "stroke":"Blue"
          }
        ]
      }
    }
     */
  }
  _exports.default = LegendItemComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LegendItemComponent);
});