define("@nycplanning/ember/components/deprecated/layer-groups-container", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/array", "@nycplanning/ember/templates/components/deprecated/layer-groups-container"], function (_exports, _component, _object, _runloop, _array, _layerGroupsContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    init() {
      this._super(...arguments);
      this.set('layerGroupToggleItems', (0, _array.A)([]));
    },
    layout: _layerGroupsContainer.default,
    classNames: ['layer-groups-container'],
    classNameBindings: ['open', 'numberMenuItems:has-active-layer-groups'],
    numberMenuItems: (0, _object.computed)('layerGroupToggleItems.@each.active', function () {
      const items = this.get('layerGroupToggleItems');
      const activeStates = items.mapBy('active');
      return activeStates.reduce((acc, curr) => {
        let mutatedAcc = acc;
        if (curr) {
          mutatedAcc += 1;
        }
        return mutatedAcc;
      }, 0);
    }),
    open: true,
    mapIsLoading: false,
    title: null,
    actions: {
      toggleLayerGroupsContainer() {
        this.toggleProperty('open');
      },
      registerChild(componentContext) {
        (0, _runloop.next)(() => {
          this.get('layerGroupToggleItems').pushObject(componentContext);
        });
      },
      unregisterChild(componentContext) {
        (0, _runloop.next)(() => {
          this.get('layerGroupToggleItems').removeObject(componentContext);
        });
      }
    }
  });
  _exports.default = _default;
});