define("ember-cli-foundation-6-sass/initializers/zf-widget", ["exports", "@ember/utils", "jquery", "foundation-sites"], function (_exports, _utils, _jquery, _foundationSites) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    // application.inject('route', 'foo', 'service:foo');
    if (_jquery.default && (0, _utils.typeOf)((0, _jquery.default)().foundation) === 'function') {
      (0, _jquery.default)().foundation();
    }
  }
  var _default = {
    name: 'zf-widget',
    initialize
  };
  _exports.default = _default;
});