define("@nycplanning/ember/components/deprecated/layer-group-toggle", ["exports", "@ember/component", "@nycplanning/ember/templates/components/deprecated/layer-group-toggle"], function (_exports, _component, _layerGroupToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    init() {
      this._super(...arguments);
      this.get('didInit')(this);
      this.set('icon', []);
    },
    classNames: ['layer-group-toggle'],
    classNameBindings: ['active'],
    layout: _layerGroupToggle.default,
    label: null,
    tooltip: '',
    infoLink: '',
    infoLinkIcon: 'external-link-alt',
    tooltipIcon: 'info-circle',
    active: true,
    activeTooltip: '',
    activeTooltipIcon: 'exclamation-triangle',
    didInit() {},
    willDestroyHook() {},
    willDestroy() {
      this.get('willDestroyHook')(this);
    },
    actions: {
      toggle() {
        this.toggleProperty('active');
      }
    }
  });
  _exports.default = _default;
});