define("@nycplanning/ember/templates/components/deprecated/legend-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "mgJZuJro",
    "block": "[[[10,1],[15,0,[29,[[36,0]]]],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[33,3,[\"layers\"]]],null]],null],null,[[[1,\"\\n\"],[41,[28,[37,5],[[33,3,[\"type\"]],\"line\"],null],[[[1,\"      \"],[1,[28,[35,6],null,[[\"options\"],[[30,1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,5],[[33,3,[\"type\"]],\"rectangle\"],null],[[[1,\"      \"],[1,[28,[35,7],null,[[\"options\"],[[30,1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,5],[[33,3,[\"type\"]],\"fa-icon\"],null],[[[1,\"      \"],[1,[28,[35,8],null,[[\"options\"],[[30,1]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"icon-layer\"],false,[\"iconType\",\"each\",\"-track-array\",\"icon\",\"if\",\"eq\",\"deprecated/icons/line\",\"deprecated/icons/rectangle\",\"deprecated/icons/fa-icon\"]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/legend-icon.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});