define("@nycplanning/ember/templates/components/deprecated/legend-item", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "EpZpuJy4",
    "block": "[[[10,0],[14,0,\"grid-x\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"cell auto\"],[12],[1,\"\\n    \"],[1,[28,[35,0],null,[[\"icon\"],[[33,1,[\"icon\"]]]]]],[1,\"\\n    \"],[1,[33,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"cell shrink\"],[12],[1,\"\\n\"],[41,[33,1,[\"tooltip\"]],[[[1,\"      \"],[1,[28,[35,3],null,[[\"tip\",\"side\",\"fixedWidth\"],[[33,1,[\"tooltip\"]],\"left\",true]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"deprecated/legend-icon\",\"item\",\"if\",\"deprecated/icon-tooltip\"]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/legend-item.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});