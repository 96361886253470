define("@nycplanning/ember/templates/components/deprecated/icons/line", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "XCdKhajo",
    "block": "[[[10,\"path\"],[14,\"d\",\"M0 5 l215 0\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/icons/line.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});