define("@nycplanning/ember/adapters/layer-group", ["exports", "ember-data", "fetch", "@ember/application"], function (_exports, _emberData, _fetch, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    JSONAPIAdapter
  } = _emberData.default;
  const DEFAULT_HOST = 'https://layers-api.planninglabs.nyc';
  class LayerGroupAdapter extends JSONAPIAdapter {
    constructor() {
      super(...arguments);
      const {
        host = DEFAULT_HOST
      } = (0, _application.getOwner)(this).resolveRegistration('config:environment')['ember-mapbox-composer'] || {};
      this.set('host', host);
      this.set('namespace', 'v1');
    }
    query(store, type) {
      let query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      const URL = this.buildURL(type.modelName);
      return (0, _fetch.default)(`${URL}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify(query)
      }).then(blob => blob.json());
    }
  }
  _exports.default = LayerGroupAdapter;
});