define("@nycplanning/ember/templates/components/deprecated/icons/fa-icon", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "C5+BuJCT",
    "block": "[[[10,1],[15,5,[36,0]],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@icon\",\"@transform\",\"@prefix\"],[[33,2,[\"fa-icon\"]],[33,2,[\"transform\"]],[33,2,[\"prefix\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"spanStyle\",\"fa-icon\",\"options\"]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/icons/fa-icon.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});