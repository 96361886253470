define("ember-mapbox-gl/components/mapbox-gl-call", ["exports", "@ember/debug", "@ember/runloop", "@ember/component"], function (_exports, _debug, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A component used to call a specific Mapbox GL method.
   *
   * @class MapboxGLCallComponent
   */
  const MapboxGlCallComponent = _component.default.extend({
    tagName: '',
    obj: null,
    func: null,
    args: null,
    onResp() {},
    params: null,
    _scheduledCall: null,
    didReceiveAttrs() {
      this._super(...arguments);
      let {
        obj,
        func,
        args,
        params
      } = this;
      if (args === null && params !== null) {
        if (func !== null) {
          args = params;
        } else {
          [func, ...args] = params;
        }
      }
      (false && !(typeof obj === 'object' && obj !== null) && (0, _debug.assert)('mapbox-gl-call obj is required', typeof obj === 'object' && obj !== null));
      (false && !(typeof func === 'string') && (0, _debug.assert)('mapbox-gl-call func is required and must be a string', typeof func === 'string'));
      (false && !(typeof obj[func] === 'function') && (0, _debug.assert)(`mapbox-gl-call ${func} must be a function on ${obj}`, typeof obj[func] === 'function'));
      this._scheduledCall = (0, _runloop.scheduleOnce)('afterRender', this, this._call, obj, func, args);
    },
    willDestroy() {
      this._super(...arguments);
      if (this._scheduledCall !== null) {
        (0, _runloop.cancel)(this._scheduledCall);
      }
    },
    _call(obj, func, args) {
      this._scheduledCall = null;
      this.onResp(obj[func].apply(obj, args));
    }
  });
  MapboxGlCallComponent.reopenClass({
    positionalParams: 'params'
  });
  var _default = MapboxGlCallComponent;
  _exports.default = _default;
});