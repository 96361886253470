define("ember-mapbox-gl/components/mapbox-gl-control", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Add a map control.
   *
   * @class MapboxGlControlComponent
   */
  const MapboxGlControlComponent = _component.default.extend({
    tagName: '',
    map: null,
    control: null,
    position: null,
    init() {
      this._super(...arguments);
      this.hasAdded = false;
      this._prevControl = null;
    },
    didReceiveAttrs() {
      this._super(...arguments);
      const {
        control,
        position
      } = this;
      if (this._prevControl !== null) {
        this.map.removeControl(this._prevControl);
      }
      if (control) {
        this.map.addControl(control, position);
        this._prevControl = control;
      } else {
        this._prevControl = null;
      }
    },
    willDestroy() {
      this._super(...arguments);
      if (this._prevControl !== null) {
        this.map.removeControl(this._prevControl);
      }
    }
  });
  MapboxGlControlComponent.reopenClass({
    positionalParams: ['control', 'position']
  });
  var _default = MapboxGlControlComponent;
  _exports.default = _default;
});