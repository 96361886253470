define("@nycplanning/ember/utils/normalize-carto-vectors", ["exports", "@nycplanning/ember/utils/carto", "@ember/array", "@ember/object", "rsvp"], function (_exports, _carto, _array, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = normalizeCartoVectors;
  const {
    getVectorTileTemplate
  } = _carto.default;
  function normalizeCartoVectors() {
    let pseudoMapboxGlSources = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    // coerce to an array
    const iterable = (0, _array.isArray)(pseudoMapboxGlSources) ? pseudoMapboxGlSources : [pseudoMapboxGlSources];

    // normalize into mapbox-gl source spec
    return _rsvp.Promise.all(iterable.map(source => {
      const {
        id,
        type,
        minzoom = 0,
        'source-layers': sourceLayers
      } = (0, _object.getProperties)(source, 'id', 'type', 'minzoom', 'source-layers');
      if (type !== 'cartovector') {
        return new _rsvp.Promise(resolve => {
          const {
            tiles,
            tileSize
          } = (0, _object.getProperties)(source, 'tiles', 'tileSize');
          resolve({
            id,
            type,
            tiles,
            tileSize
          });
        });
      }
      return getVectorTileTemplate(sourceLayers).then(template => ({
        id,
        type: 'vector',
        tiles: [template],
        minzoom
      }));
    }));
  }
});