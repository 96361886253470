define("@nycplanning/ember/components/deprecated/site-header", ["exports", "@ember/component", "@nycplanning/ember/templates/components/deprecated/site-header"], function (_exports, _component, _siteHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: 'header',
    classNames: ['site-header'],
    layout: _siteHeader.default,
    ariaRole: 'banner',
    closed: true,
    responsiveNav: false,
    responsiveSize: 'large',
    betaNotice: function () {
      return this.args.betaNotice || false;
    }
  });
  _exports.default = _default;
});