define("ember-mapbox-gl/components/mapbox-gl-marker", ["exports", "@ember/debug", "@ember/polyfills", "@ember/application", "@ember/component", "ember-mapbox-gl/templates/components/mapbox-gl-marker"], function (_exports, _debug, _polyfills, _application, _component, _mapboxGlMarker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * A utility that brokers HTTP requests...
   *
   * @class MapboxGlMarker
   */
  var _default = _component.default.extend({
    layout: _mapboxGlMarker.default,
    tagName: '',
    MapboxGl: null,
    map: null,
    initOptions: null,
    lngLat: null,
    init() {
      this._super(...arguments);
      this.domContent = document.createElement('div');
      const {
        lngLat,
        initOptions
      } = this;
      (false && !(lngLat) && (0, _debug.assert)('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      const options = (0, _polyfills.assign)({}, ((0, _application.getOwner)(this).resolveRegistration('config:environment')['mapbox-gl'] ?? {}).marker, initOptions);
      this.marker = new this.MapboxGl.Marker(this.domContent, options).setLngLat(lngLat).addTo(this.map);
    },
    didUpdateAttrs() {
      this._super(...arguments);
      const lngLat = this.lngLat;
      (false && !(lngLat) && (0, _debug.assert)('mapbox-gl-marker requires lngLat, maybe you passed latLng?', lngLat));
      this.marker.setLngLat(lngLat);
    },
    willDestroy() {
      this._super(...arguments);
      this.marker.remove();
    }
  });
  _exports.default = _default;
});