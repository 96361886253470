define("@nycplanning/ember/components/labs-map-legend-area", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <rect width="6" height="6" x="5" y="1" rx="1" ry="1" />
  
  {{yield}}
  
  */
  {
    "id": "WJIC8KWU",
    "block": "[[[10,\"rect\"],[14,\"width\",\"6\"],[14,\"height\",\"6\"],[14,\"x\",\"5\"],[14,\"y\",\"1\"],[14,\"rx\",\"1\"],[14,\"ry\",\"1\"],[12],[13],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@nycplanning/ember/components/labs-map-legend-area.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});