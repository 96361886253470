define("ember-composable-helpers/helpers/union", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.union = union;
  function union(_ref) {
    let [...arrays] = _ref;
    let items = [].concat(...arrays);
    return items.filter((value, index, array) => array.indexOf(value) === index);
  }
  var _default = (0, _helper.helper)(union);
  _exports.default = _default;
});