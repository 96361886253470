define("@nycplanning/ember/templates/components/deprecated/legend-items", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "4s27BGZQ",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[33,2]],null]],null],null,[[[1,\"  \"],[1,[28,[35,3],null,[[\"item\"],[[30,1]]]]],[1,\"\\n\"]],[1]],null]],[\"item\"],false,[\"each\",\"-track-array\",\"items\",\"deprecated/legend-item\"]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/legend-items.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});