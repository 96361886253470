define("@nycplanning/ember/transforms/hash", ["exports", "ember-data", "@ember/object"], function (_exports, _emberData, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Transform
  } = _emberData.default;
  class _default extends Transform {
    deserialize(serialized) {
      return _object.default.create(serialized);
    }
  }
  _exports.default = _default;
});