define("@nycplanning/ember/components/deprecated/legend-icon", ["exports", "@ember/component", "@ember/object", "@nycplanning/ember/templates/components/deprecated/legend-icon"], function (_exports, _component, _object, _legendIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    init() {
      this._super(...arguments);
    },
    icon: null,
    classNames: ['legend-icon'],
    layout: _legendIcon.default,
    iconType: (0, _object.computed)(function () {
      const type = this.get('icon.type');
      return type === 'fa-icon' ? 'fa-layers' : type;
    })
  });
  _exports.default = _default;
});