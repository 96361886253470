define("@nycplanning/ember/components/labs-ui/icon-tooltip", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- NOTE: Whitespace is purposefully avoided inside the markup's <span> as to not alter the layout --}}
  <span class='icon-tooltip'><FaIcon
    @icon={{this.icon}}
    @transform={{@transform}}
    @fixedWidth={{this.fixedWidth}}
  /><EmberTooltip
    @text={{@tip}}
    @side={{this.side}}
    @popperContainer="body"
  /></span>
  
  */
  {
    "id": "0qe+Y7t+",
    "block": "[[[10,1],[14,0,\"icon-tooltip\"],[12],[8,[39,0],null,[[\"@icon\",\"@transform\",\"@fixedWidth\"],[[30,0,[\"icon\"]],[30,1],[30,0,[\"fixedWidth\"]]]],null],[8,[39,1],null,[[\"@text\",\"@side\",\"@popperContainer\"],[[30,2],[30,0,[\"side\"]],\"body\"]],null],[13],[1,\"\\n\"]],[\"@transform\",\"@tip\"],false,[\"fa-icon\",\"ember-tooltip\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/icon-tooltip.hbs",
    "isStrictMode": false
  });
  class IconTooltipComponent extends _component2.default {
    get icon() {
      return this.args.icon || 'info-circle';
    }
    get fixedWidth() {
      return this.args.fixedWidth || false;
    }
    get side() {
      return this.args.side || 'top';
    }
  }
  _exports.default = IconTooltipComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, IconTooltipComponent);
});