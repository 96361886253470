define("@nycplanning/ember/components/deprecated/legend-item", ["exports", "@ember/component", "@nycplanning/ember/templates/components/deprecated/legend-item"], function (_exports, _component, _legendItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: ['legend-item'],
    item: null,
    layout: _legendItem.default
  });
  _exports.default = _default;
});