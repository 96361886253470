define("@nycplanning/ember/templates/components/deprecated/layer-groups-container", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "Z0gGf6HE",
    "block": "[[[11,0],[16,0,[29,[\"layer-groups-container-title \",[52,[51,[33,1]],\"closed\"],\" layer-groups-container--\",[28,[37,2],[[33,3]],null]]]],[24,\"role\",\"button\"],[4,[38,4],[[30,0],\"toggleLayerGroupsContainer\"],null],[12],[1,\"\\n  \"],[1,[34,3]],[1,\"\\n\"],[41,[33,6],[[[1,\"    \"],[10,1],[14,0,\"badge\"],[12],[1,[34,6]],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,7],[[33,8],[33,1],[33,6]],null],[[[1,\"    \"],[8,[39,9],[[24,0,\"fa-spin medium-gray\"]],[[\"@icon\"],[\"spinner\"]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"],[10,0],[14,0,\"layer-groups-container-content\"],[12],[1,\"\\n  \"],[18,1,[[28,[37,11],null,[[\"layer-group-toggle\"],[[50,\"deprecated/layer-group-toggle\",0,null,[[\"willDestroyHook\",\"didInit\"],[[28,[37,4],[[30,0],\"unregisterChild\"],null],[28,[37,4],[[30,0],\"registerChild\"],null]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"unless\",\"open\",\"dasherize\",\"title\",\"action\",\"if\",\"numberMenuItems\",\"and\",\"mapIsLoading\",\"fa-icon\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/layer-groups-container.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});