define("@nycplanning/ember/components/labs-map-legend-point", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <circle cx="8" cy="4" r="2.5" />
  
  {{yield}}
  
  */
  {
    "id": "6/Fn8Rp0",
    "block": "[[[10,\"circle\"],[14,\"cx\",\"8\"],[14,\"cy\",\"4\"],[14,\"r\",\"2.5\"],[12],[13],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@nycplanning/ember/components/labs-map-legend-point.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});