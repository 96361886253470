define("@nycplanning/ember/components/deprecated/legend-items", ["exports", "@ember/component", "@nycplanning/ember/templates/components/deprecated/legend-items"], function (_exports, _component, _legendItems) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    classNames: ['legend-items'],
    items: null,
    layout: _legendItems.default
  });
  _exports.default = _default;
});