define("@nycplanning/ember/components/labs-ui/site-header-pre", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  
  */
  {
    "id": "fVxBBib4",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/site-header-pre.hbs",
    "isStrictMode": false
  });
  class SiteHeaderPreComponent extends _component2.default {}
  _exports.default = SiteHeaderPreComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SiteHeaderPreComponent);
});