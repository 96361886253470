define("@nycplanning/ember/components/labs-map-legend-line", ["exports", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <path d="M0 5 l215 0" />
  
  {{yield}}
  
  */
  {
    "id": "vGFLcaay",
    "block": "[[[10,\"path\"],[14,\"d\",\"M0 5 l215 0\"],[12],[13],[1,\"\\n\\n\"],[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@nycplanning/ember/components/labs-map-legend-line.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
  _exports.default = _default;
});