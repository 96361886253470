define("ember-mapbox-gl/components/mapbox-gl-popup", ["exports", "@ember/polyfills", "@ember/application", "@ember/runloop", "@ember/component", "ember-mapbox-gl/templates/components/mapbox-gl-popup"], function (_exports, _polyfills, _application, _runloop, _component, _mapboxGlPopup) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Adds a [popup](https://www.mapbox.com/mapbox-gl-js/api/#popup) to the map.
  
    ### Properties
    - `lngLat`
      - The longitude and latitude to pin the popup at.
  
    ### Example
    ```hbs
    {{#mapbox-gl as |map|}}
      {{#map.source options=(hash
        type='geojson'
        data=(hash
          type='FeatureCollection'
          features=(array
            (hash
              type='Feature'
              geometry=(hash
                type='Point'
                coordinates=(array -96.7969879 32.7766642)
              )
            )
          )
        )) as |source|}}
        {{source.layer layer=(hash
            type='circle'
            paint=(hash circle-color='#007cbf' circle-radius=10))}}
      {{/map.source}}
  
      {{#map.popup lngLat=(array -96.7969879 32.7766642)}}
        Dallas, TX
      {{/map.popup}}
    {{/mapbox-gl}}
    ```
  
    @class MapboxGLPopup
   */
  var _default = _component.default.extend({
    layout: _mapboxGlPopup.default,
    tagName: '',
    MapboxGl: null,
    map: null,
    marker: null,
    lngLat: null,
    initOptions: null,
    onClose() {},
    init() {
      this._super(...arguments);
      const {
        initOptions,
        marker
      } = this;
      this.domContent = document.createElement('div');
      this._onClose = (0, _runloop.bind)(this, this.onClose);
      const options = (0, _polyfills.assign)({}, ((0, _application.getOwner)(this).resolveRegistration('config:environment')['mapbox-gl'] ?? {}).popup, initOptions);
      this.popup = new this.MapboxGl.Popup(options).setDOMContent(this.domContent).on('close', this._onClose);
      if (marker === null) {
        this.popup.addTo(this.map);
      } else {
        marker.setPopup(this.popup);
      }
    },
    didReceiveAttrs() {
      this._super(...arguments);
      const lngLat = this.lngLat;
      if (lngLat) {
        if (this.popup.isOpen()) {
          this.popup.setLngLat(lngLat);
        } else {
          this.popup.remove();
          this.popup.addTo(this.map);
          this.popup.setLngLat(lngLat);
        }
      }
    },
    willDestroy() {
      this._super(...arguments);
      this.popup.off('close', this._onClose);
      const marker = this.marker;
      if (marker === null) {
        this.popup.remove();
      } else {
        marker.setPopup(null);
      }
    }
  });
  _exports.default = _default;
});