define("@nycplanning/ember/templates/components/deprecated/icon-tooltip", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "yK9zBWUM",
    "block": "[[[1,[28,[35,0],null,[[\"icon\",\"transform\",\"fixedWidth\"],[[33,1],[33,2],[33,3]]]]],[1,[28,[35,4],null,[[\"text\",\"side\",\"popperContainer\"],[[33,5],[33,6],\"body\"]]]],[1,\"\\n\"]],[],false,[\"fa-icon\",\"icon\",\"transform\",\"fixedWidth\",\"ember-tooltip\",\"tip\",\"side\"]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/icon-tooltip.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});