define("@nycplanning/ember/models/source", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr
  } = _emberData.default;

  /**
    Model for related sources
  
    @public
    @class SourceModel
  */
  var _default = Model.extend({
    meta: attr()
  });
  _exports.default = _default;
});