define("@nycplanning/ember/templates/components/deprecated/icons/rectangle", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "tJbCO+qb",
    "block": "[[[10,\"path\"],[14,\"d\",\"M3 2L14 2L14 8L3 8Z\"],[12],[13],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "@nycplanning/ember/templates/components/deprecated/icons/rectangle.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});