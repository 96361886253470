define("@nycplanning/ember/components/labs-ui/legend-items", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="legend-items">
    {{#each @items as |item|}}
      <LabsUi::LegendItem @item={{item}} />
    {{/each}}
  </div>
  
  */
  {
    "id": "8/vbvEc3",
    "block": "[[[10,0],[14,0,\"legend-items\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@item\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[13],[1,\"\\n\"]],[\"@items\",\"item\"],false,[\"each\",\"-track-array\",\"labs-ui/legend-item\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/legend-items.hbs",
    "isStrictMode": false
  });
  class LegendItemsComponent extends _component2.default {}
  _exports.default = LegendItemsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, LegendItemsComponent);
});