define("@nycplanning/ember/initializers/consumer-environment", ["exports", "@nycplanning/ember/configuration"], function (_exports, _configuration) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    (0, _configuration.setConfiguration)(application.resolveRegistration('config:environment'));
  }
  var _default = {
    initialize
  };
  _exports.default = _default;
});