define("ember-cli-foundation-6-sass/components/zf-tabs", ["exports", "@ember/component", "ember-cli-foundation-6-sass/mixins/zf-widget"], function (_exports, _component, _zfWidget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend(_zfWidget.default, {
    /** @member tag type */
    tagName: 'ul',
    /** @member Class names */
    classNames: ['tabs'],
    /** @member Attribute bindings */
    attributeBindings: ['data-tabs'],
    /** @member Makes the data attribute binding appear */
    'data-tabs': ' ',
    /** @member Foundation type */
    'zfType': 'Tabs',
    /** @member Foundation specific options */
    'zfOptions': ['autoFocus', 'wrapOnKeys', 'matchHeight', 'linkClass', 'panelClass']
  });
  _exports.default = _default;
});