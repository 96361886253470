define("ember-cli-foundation-6-sass/templates/components/zf-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4xrGnHaU",
    "block": "[[[41,[48,[30,1]],[[[41,[33,2],[[[1,\"    \"],[18,1,[[30,0],[33,2]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[18,1,null],[1,\"\\n\\n\"]],[]],[[[1,\"\\n\"],[41,[33,2],[[[1,\"    \"],[1,[33,2,[\"message\"]]],[1,\"\\n\\n\"]],[]],null],[1,\"\\n\"],[41,[33,4],[[[1,\"    \"],[1,[34,4]],[1,\"\\n\"]],[]],null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"flash\",\"yield\",\"content\"]]",
    "moduleName": "ember-cli-foundation-6-sass/templates/components/zf-callout.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});