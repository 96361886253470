define("ember-mapbox-gl/components/mapbox-gl-on", ["exports", "@ember/debug", "@ember/object", "@ember/utils", "@ember/runloop", "@ember/component"], function (_exports, _debug, _object, _utils, _runloop, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Adds an action to listen for [mapbox events](https://www.mapbox.com/mapbox-gl-js/api/#map#on).
  
    ### Positional Parameters
    - `eventSource`
      - The first positional parameter. The event type to listen for.
    - `action`
      - The second positional parameter. The action provided by the consumer to call when the event is triggered.
  
    ### Example
    ```hbs
    {{#mapbox-gl as |map|}}
      {{map.on 'click' (action 'mapClicked')}}
    {{/mapbox-gl}}
    ```
  
    ```javascript
    import Controller from '@ember/controller';
  
    export default Controller.extend({
      actions: {
        mapClicked({ target: map, point }) {
          console.log(map, point);
        }
      }
    });
    ```
  
    @class MapboxGLOn
   */
  const MapboxGlOnComponent = _component.default.extend({
    tagName: '',
    eventSource: null,
    event: null,
    layerId: null,
    action: null,
    _prevEvent: null,
    _prevLayerId: null,
    _event: (0, _object.computed)('event', function () {
      const event = this.event;
      (false && !(typeof event === 'string') && (0, _debug.assert)(`mapbox-gl-event requires event to be a string, was ${event}`, typeof event === 'string'));
      return event;
    }).readOnly(),
    _layerId: (0, _object.computed)('layerId', '_action', function () {
      const {
        layerId,
        _action
      } = this;
      if (layerId === _action) {
        return null;
      }
      return layerId;
    }).readOnly(),
    _action: (0, _object.computed)('layerId', 'action', function () {
      const {
        layerId,
        action
      } = this;
      return action || layerId;
    }).readOnly(),
    init() {
      this._super(...arguments);
      this._boundOnEvent = _runloop.run.bind(this, this._onEvent);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      const {
        eventSource,
        _layerId,
        _event,
        _prevEvent,
        _prevLayerId,
        _action
      } = this;
      (false && !((0, _utils.isPresent)(eventSource)) && (0, _debug.assert)('mapbox-gl-event requires an eventSource', (0, _utils.isPresent)(eventSource)));
      (false && !((0, _utils.isPresent)(_action)) && (0, _debug.assert)('mapbox-gl-event requires an action', (0, _utils.isPresent)(_action)));
      if (_event !== _prevEvent || _layerId !== _prevLayerId) {
        if (_prevEvent) {
          if (_prevLayerId) {
            eventSource.off(_prevEvent, _prevLayerId, this._boundOnEvent);
          } else {
            eventSource.off(_prevEvent, this._boundOnEvent);
          }
        }
        this._prevEvent = _event;
        this._prevLayerId = _layerId;
        if (_layerId) {
          eventSource.on(_event, _layerId, this._boundOnEvent);
        } else {
          eventSource.on(_event, this._boundOnEvent);
        }
      }
    },
    willDestroy() {
      this._super(...arguments);
      const {
        eventSource,
        _prevEvent,
        _prevLayerId
      } = this;
      if (eventSource && _prevEvent) {
        if (_prevLayerId) {
          eventSource.off(_prevEvent, _prevLayerId, this._boundOnEvent);
        } else {
          eventSource.off(_prevEvent, this._boundOnEvent);
        }
      }
    },
    _onEvent() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this._action(...arguments);
    }
  });
  MapboxGlOnComponent.reopenClass({
    positionalParams: ['event', 'layerId', 'action']
  });
  var _default = MapboxGlOnComponent;
  _exports.default = _default;
});