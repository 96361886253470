define("ember-mapbox-gl/templates/components/mapbox-gl-source", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "2p0M9W5W",
    "block": "[[[18,1,[[28,[37,1],null,[[\"layer\",\"id\"],[[50,\"mapbox-gl-layer\",0,null,[[\"map\",\"_sourceId\"],[[30,0,[\"map\"]],[30,0,[\"sourceId\"]]]]],[30,0,[\"sourceId\"]]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-source.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});