define("@nycplanning/ember/components/labs-layers", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@turf/union", "@ember/array/proxy", "@ember/debug"], function (_exports, _component, _templateFactory, _object, _union, _proxy, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- We need to detect layer order so bind event to all mouse interactions--}}
  {{mapbox-gl-on "mousemove" (action "handleLayerMouseMove")
    eventSource=map}}
  
  {{!-- Bind layer groups" layers --}}
  {{#each layers key="style" as |layerObject|}}
    {{mapbox-gl-layer
      map=map
      layer=layerObject.mapboxGlStyle
      before=layerObject.before
    }}
    {{!-- if the layer is hightlightable or tooltipable, bind events --}}
    {{#if (or layerObject.highlightable layerObject.tooltipable)}}
      {{mapbox-gl-on "mouseleave" layerObject.mapboxGlStyle.id (action "handleLayerMouseLeave")
        eventSource=map}}
    {{/if}}
  
    {{#if layerObject.clickable}}
      {{mapbox-gl-on "click" layerObject.mapboxGlStyle.id (action "handleLayerMouseClick")
        eventSource=map}}
    {{/if}}
  {{/each}}
  
  {{yield (hash
    tooltip=(if (and mousePosition hoveredFeature hoveredLayer.tooltipable)
      (component toolTipComponent
        feature=hoveredFeature
        layer=hoveredLayer
        mousePosition=mousePosition
        top=mousePosition.y
        left=mousePosition.x
      )
    )
  )}}
  
  */
  {
    "id": "n2RtRaPt",
    "block": "[[[1,[28,[35,0],[\"mousemove\",[28,[37,1],[[30,0],\"handleLayerMouseMove\"],null]],[[\"eventSource\"],[[33,2]]]]],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[33,5]],null]],null],\"style\",[[[1,\"  \"],[1,[28,[35,6],null,[[\"map\",\"layer\",\"before\"],[[33,2],[30,1,[\"mapboxGlStyle\"]],[30,1,[\"before\"]]]]]],[1,\"\\n\"],[41,[28,[37,8],[[30,1,[\"highlightable\"]],[30,1,[\"tooltipable\"]]],null],[[[1,\"    \"],[1,[28,[35,0],[\"mouseleave\",[30,1,[\"mapboxGlStyle\",\"id\"]],[28,[37,1],[[30,0],\"handleLayerMouseLeave\"],null]],[[\"eventSource\"],[[33,2]]]]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"clickable\"]],[[[1,\"    \"],[1,[28,[35,0],[\"click\",[30,1,[\"mapboxGlStyle\",\"id\"]],[28,[37,1],[[30,0],\"handleLayerMouseClick\"],null]],[[\"eventSource\"],[[33,2]]]]],[1,\"\\n\"]],[]],null]],[1]],null],[1,\"\\n\"],[18,2,[[28,[37,10],null,[[\"tooltip\"],[[52,[28,[37,11],[[33,12],[33,13],[33,14,[\"tooltipable\"]]],null],[50,[33,16],0,null,[[\"feature\",\"layer\",\"mousePosition\",\"top\",\"left\"],[[33,13],[33,14],[33,12],[33,12,[\"y\"]],[33,12,[\"x\"]]]]]]]]]]],[1,\"\\n\"]],[\"layerObject\",\"&default\"],false,[\"mapbox-gl-on\",\"action\",\"map\",\"each\",\"-track-array\",\"layers\",\"mapbox-gl-layer\",\"if\",\"or\",\"yield\",\"hash\",\"and\",\"mousePosition\",\"hoveredFeature\",\"hoveredLayer\",\"component\",\"toolTipComponent\"]]",
    "moduleName": "@nycplanning/ember/components/labs-layers.hbs",
    "isStrictMode": false
  });
  /**
    Renders a collection of Mapbox Composer-compatible layer groups.
  
    ```js
    // routes/application.js
    import Route from '@ember/routing/route';
  
    export default class ApplicationRoute extends Route {
      async model() {
        return [{
          id: 'roads',
          layers: [{
            id: 'highways',
            style: {
              type: 'line',
              paint: {
                'line-fill': 'orange',
              },
            },
          }, {
            id: 'streets',
            style: {
              type: 'line',
              paint: {
                'line-fill': 'blue',
              },
            },
          }]
        }];
      }
    }
  
    ```
    ```handlebars
    {{!-- routes/application.hbs --}}
    {{#labs-map as |map|}}
      {{map.labs-layers layerGroups=model}}
    {{/labs-map}}
    ```
  
    @class LayersComponent
    @public
  */
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    /**
      Reference to a instance of a MapboxGL map. Handled internally when using contextual components:
       ```
    {{#labs-map as |map|}}
      {{map.labs-layers layerGroups=model}}
    {{/labs-map}}
      ```
      @argument map
      @private
      @type MapboxGL Map Instance
    */
    map: null,
    /**
      Whether layergroups should have interactivity (highlighting and clicking).  Useful for temporarily disabling interactivity during drawing mode.
       @argument interactivity
      @type boolean
    */
    interactivity: true,
    /**
      Collection of layer-group objects
      @argument layerGroups
      @type Array
    */
    layerGroups: null,
    /**
      Event fired on layer click. Scoped to individual layers. Returns the mouse event and clicked layer.
      @argument onLayerClick
      @type Action
    */
    onLayerClick() {},
    /**
      Event fired on layer mousemove. Scoped to individual layers. Returns the mouse event and found layer.
      @argument onLayerMouseMove
      @type Action
    */
    onLayerMouseMove() {},
    /**
      Event fired on layer mouseleave. Scoped to individual layers. Returns the mouse event and found layer.
      @argument onLayerMouseLeave
      @type Action
    */
    onLayerMouseLeave() {},
    /**
      Event fired on layer highlight. Returns the id of the layer that is being highlighted.
      @argument onLayerHighlight
      @type Action
    */
    onLayerHighlight() {},
    /**
      Name of local component to use in place of default component.
      @argument toolTipComponent
      @type String
    */
    toolTipComponent: 'labs-layers-tooltip',
    hoveredFeature: null,
    hoveredLayer: (0, _object.computed)('hoveredFeature', function () {
      const feature = this.get('hoveredFeature');
      if (feature) {
        return this.get('layers').findBy('id', feature.layer.id);
      }
      return null;
    }),
    layers: (0, _object.computed)('layerGroups.@each.layers', function () {
      return _proxy.default.create({
        content: this.get('layerGroups').map(layerGroup => (0, _object.get)(layerGroup, 'layers')).reduce((accumulator, current) => {
          const layers = current.toArray();
          return [...accumulator, ...layers];
        }, [])
      });
    }),
    interactiveLayerIds: (0, _object.computed)('layers.@each.visibility', function () {
      return this.get('layers').filterBy('visibility', true).filter(_ref2 => {
        let {
          highlightable,
          tooltipable,
          clickable
        } = _ref2;
        return highlightable || tooltipable || clickable;
      }).map(layer => layer.get('id'));
    }),
    mousePosition: null,
    stitchHoveredTiles(feature) {
      const map = this.get('map');
      (false && (0, _debug.warn)(`Missing ID in properties for ${feature.layer.source}`, feature.properties.id, {
        id: 'ember-mapbox-composer.id-missing'
      })); // require an id for stitching
      if (!feature.properties.id) return feature;

      // query for features by source
      const featureFragments = map.querySourceFeatures(feature.layer.source, {
        sourceLayer: feature.layer['source-layer'],
        filter: ['==', 'id', feature.properties.id]
      }).map(_ref3 => {
        let {
          geometry,
          properties
        } = _ref3;
        return {
          type: 'Feature',
          properties,
          geometry
        };
      });

      // we don't need to union if there is only one
      // we also don't want to slow down machines if there are too many
      if (featureFragments.length === 1 || featureFragments.length > 100) return feature;
      // if the fragments are features of type "Point", return the first one instead of unioning
      if (featureFragments.length > 0 && featureFragments[0].geometry.type === "Point") return featureFragments[0];
      return featureFragments.reduce((acc, curr) => (0, _union.default)(curr, acc ? acc : curr));
    },
    actions: {
      async handleLayerMouseClick(e) {
        // TODO: stitch clicked feature
        const {
          features: [feature]
        } = e;
        const interactivity = this.get('interactivity');
        const foundLayer = this.get('layers').findBy('id', feature.layer.id);
        const layerClickEvent = this.get('onLayerClick');
        if (layerClickEvent && feature && interactivity) {
          const {
            geometry
          } = this.stitchHoveredTiles(feature);
          feature.geometry = geometry;
          layerClickEvent(feature, foundLayer);
        }
      },
      async handleLayerMouseMove(e) {
        // only query the visible layers
        const layerIds = this.get('interactiveLayerIds');
        const [feature] = this.map.queryRenderedFeatures(e.point, {
          layers: layerIds
        });
        if (!feature) return;
        const map = this.get('map');
        const interactivity = this.get('interactivity');
        const foundLayer = this.get('layers').findBy('id', feature.layer.id);

        // this layer-specific event should always be called
        // if it's available
        const mouseMoveEvent = this.get('onLayerMouseMove');
        mouseMoveEvent(e, foundLayer);
        const {
          highlightable,
          tooltipable,
          clickable
        } = foundLayer.getProperties('highlightable', 'tooltipable', 'clickable');
        if (clickable) {
          map.getCanvas().style.cursor = 'pointer';
        }

        // if layer is set for this behavior
        if ((highlightable || tooltipable) && interactivity) {
          const hoveredFeature = this.get('hoveredFeature');
          let isNew = true;
          if (hoveredFeature) {
            if (feature.properties.id === hoveredFeature.properties.id && feature.layer.id === hoveredFeature.layer.id) {
              isNew = false;
            }
          }
          if (isNew) {
            const highlightEvent = this.get('onLayerHighlight');
            // if this is different from the currently highlighted feature
            highlightEvent(e, foundLayer);

            // only stitch if it's for highlighting and new
            // query for features of a given source
            const {
              geometry
            } = this.stitchHoveredTiles(feature);
            feature.geometry = geometry;

            // set the hovered feature
            this.set('hoveredFeature', feature);
            map.getSource('hovered-feature').setData(feature);
            if (feature.layer.type == "circle") {
              map.setLayoutProperty('highlighted-feature-circle', 'visibility', 'visible');
              map.setLayoutProperty('highlighted-feature-line', 'visibility', 'none');
            } else {
              map.setLayoutProperty('highlighted-feature-circle', 'visibility', 'none');
              map.setLayoutProperty('highlighted-feature-line', 'visibility', 'visible');
            }
          }
        }
        this.set('mousePosition', e.point);
      },
      handleLayerMouseLeave() {
        const map = this.get('map');
        this.set('hoveredFeature', null);
        map.getCanvas().style.cursor = '';
        this.setProperties({
          hoveredFeature: null,
          mousePosition: null
        });
        map.setLayoutProperty('highlighted-feature-circle', 'visibility', 'none');
        map.setLayoutProperty('highlighted-feature-line', 'visibility', 'none');
        const mouseLeaveEvent = this.get('onLayerMouseLeave');
        if (mouseLeaveEvent) {
          mouseLeaveEvent();
        }
      }
    }
  }));
  _exports.default = _default;
});