define("@nycplanning/ember/components/needs-async-state", ["exports", "@ember/component", "@ember/template-factory", "@ember/object"], function (_exports, _component, _templateFactory, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isState}}
    {{yield (get this.taskInstance this.state)}}
  {{/if}}
  
  */
  {
    "id": "SXekMe0x",
    "block": "[[[41,[30,0,[\"isState\"]],[[[1,\"  \"],[18,1,[[28,[37,2],[[30,0,[\"taskInstance\"]],[30,0,[\"state\"]]],null]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\",\"get\"]]",
    "moduleName": "@nycplanning/ember/components/needs-async-state.hbs",
    "isStrictMode": false
  });
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    tagName: '',
    isState: (0, _object.computed)('taskInstance.{isRunning,value,error}', 'state', function () {
      return this.taskInstance && !!(0, _object.get)(this.taskInstance, this.state);
    })
  }));
  _exports.default = _default;
});