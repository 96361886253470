define("@nycplanning/ember/components/deprecated/icons/fa-icon", ["exports", "@ember/component", "@ember/object", "@ember/template", "@nycplanning/ember/templates/components/deprecated/icons/fa-icon"], function (_exports, _component, _object, _template, _faIcon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    init() {
      this._super(...arguments);
    },
    tagName: 'span',
    classNames: ['legend-icon-layer'],
    layout: _faIcon.default,
    options() {},
    spanStyle: (0, _object.computed)('options.color', function () {
      return (0, _template.htmlSafe)(this.options.color ? `color: ${this.options.color}` : '');
    })
  });
  _exports.default = _default;
});