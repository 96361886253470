define("@nycplanning/ember/components/deprecated/icon-tooltip", ["exports", "@ember/component", "@nycplanning/ember/templates/components/deprecated/icon-tooltip"], function (_exports, _component, _iconTooltip) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _component.default.extend({
    tagName: 'span',
    classNames: 'icon-tooltip',
    layout: _iconTooltip.default,
    tip: '',
    side: 'top',
    icon: 'info-circle',
    transform: '',
    fixedWidth: false
  });
  _exports.default = _default;
});