define("@nycplanning/ember/models/layer", ["exports", "ember-data/model", "@ember/object", "@ember/object/computed", "ember-copy", "@ember/polyfills", "@ember/runloop", "ember-data"], function (_exports, _model, _object, _computed, _emberCopy, _polyfills, _runloop, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo
  } = _emberData.default;

  /**
    Model for individual layers. Belongs to a layer-group. May be called individually for state changes.
  
    @public
    @class LayerModel
  */
  var _default = _model.default.extend({
    init() {
      this._super(...arguments);

      // enforce presence of blank object for mapboxGL validation
      if (!this.get('style.layout')) this.set('style.layout', {});

      // determine which is the first occurring layer
      // for testing, should check that a related layer group exists
      if (this.get('layerGroup') && !this.get('layerGroup._firstOccurringLayer')) {
        this.set('layerGroup._firstOccurringLayer', this.get('id'));
        this.set('position', 1);
      }
      this.delegateVisibility();
      this.addObserver('layerGroup.visible', this, 'delegateVisibility');
    },
    delegateVisibility() {
      const visible = this.get('layerGroup.visible');
      if (this.get('layerVisibilityType') === 'singleton') {
        if (this.get('position') === 1 && this.get('layerGroup.visible')) {
          (0, _runloop.next)(() => this.set('visibility', true));
        } else {
          (0, _runloop.next)(() => this.set('visibility', false));
        }
      } else {
        (0, _runloop.next)(() => this.set('visibility', visible));
      }
    },
    layerGroup: belongsTo('layer-group', {
      async: false
    }),
    position: attr('number', {
      defaultValue: -1
    }),
    before: attr('string', {
      defaultValue: 'boundary_country'
    }),
    displayName: attr('string'),
    style: attr('hash', {
      defaultValue: () => ({})
    }),
    /**
      Determines whether to fire mouseover events for the layer.
      @property highlightable
      @type Boolean
    */
    highlightable: attr('boolean', {
      defaultValue: false
    }),
    /**
      Determines whether to fire click events for the layer.
      @property clickable
      @type Boolean
    */
    clickable: attr('boolean', {
      defaultValue: false
    }),
    /**
      Determines whether to render positioned tooltip components for the layer.
      @property tooltipable
      @type Boolean
    */
    tooltipable: attr('boolean', {
      defaultValue: false
    }),
    /**
      Optional template for tooltips. Does not handle any rendering.
      @property tooltipTemplate
      @type String
    */
    tooltipTemplate: attr('string', {
      defaultValue: ''
    }),
    paint: (0, _computed.alias)('style.paint'),
    layout: (0, _computed.alias)('style.layout'),
    layerVisibilityType: (0, _computed.alias)('layerGroup.layerVisibilityType'),
    /**
      Computed alias that returns a newly built mapbox layer object. Necessary to maintain state bindings.
      @property mapboxGlStyle
      @type Object
      @private
    */
    mapboxGlStyle: (0, _object.computed)('style.{paint,layout,filter}', function () {
      return this.get('style');
    }),
    /**
      Getter and setter for filter. Array structure should follow Mapbox's [Expression](https://www.mapbox.com/mapbox-gl-js/style-spec/#expressions) syntax.
      @property filter
      @type Array
    */
    filter: (0, _object.computed)('style.filter', {
      get() {
        return this.get('style.filter');
      },
      set(key, filter) {
        this.set('style', (0, _polyfills.assign)({}, this.get('style'), {
          filter
        }));
      }
    }),
    /**
      Getter and setter for visibility. Mutates a Mapbox property that actually determines visibility. Depends on parent visibility.
       @property visibility
      @type Boolean
    */
    visibility: (0, _object.computed)('layout.visibility', {
      get() {
        return this.get('layout.visibility') === 'visible';
      },
      set(key, value) {
        const parentVisibilityState = value && this.get('layerGroup.visible');
        const visibility = parentVisibilityState ? 'visible' : 'none';
        const layout = (0, _emberCopy.copy)(this.get('layout'));
        if (layout) {
          (0, _object.set)(layout, 'visibility', visibility);
          this.set('layout', layout);
        }
        return visibility === 'visible';
      }
    })
  });
  _exports.default = _default;
});