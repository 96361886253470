define("@nycplanning/ember/components/labs-layers-tooltip", ["exports", "@ember/component", "@ember/template-factory", "@ember/object", "@ember/template"], function (_exports, _component, _templateFactory, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="map-tooltip" style={{style}}>
    {{yield (hash feature=feature layer=layer)}}
  </div>
  
  */
  {
    "id": "yAMWSFVW",
    "block": "[[[10,0],[14,0,\"map-tooltip\"],[15,5,[36,0]],[12],[1,\"\\n  \"],[18,1,[[28,[37,2],null,[[\"feature\",\"layer\"],[[33,3],[33,4]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&default\"],false,[\"style\",\"yield\",\"hash\",\"feature\",\"layer\"]]",
    "moduleName": "@nycplanning/ember/components/labs-layers-tooltip.hbs",
    "isStrictMode": false
  });
  /**
    Renders a component when a "tooltipable" layer is hovered. 
    
    ```js
    // routes/application.js
    import Route from '@ember/routing/route';
  
    export default class ApplicationRoute extends Route {
      async model() {
        return [{
          id: 'roads',
          layers: [{
            id: 'highways',
            tooltipable: true,
            style: {
              type: 'line',
              paint: {
                'line-fill': 'orange',
              },
            },
          }, {
            id: 'streets',
            tooltipable: true,
            style: {
              type: 'line',
              paint: {
                'line-fill': 'blue',
              },
            },
          }]
        }];
      }
    }
    ```
    ```handlebars
  {{!-- routes/application.hbs --}}
  {{#labs-map as |map|}} 
    {{#map.labs-layers layerGroups=model as |layers|}}
      {{#layers.tooltip as |tip|}}
        This text appears over the tooltipable layer on mouseover. Yields a hash with two properties:
         - {{tip.feature}}
         - {{tip.layer}}
      {{/layers.tooltip}}
    {{/map}}
  {{/labs-map}}
    ```
  
    Behavior can be overridden with a different component by passing the name of a local component to `labs-layers`.
  
    @class LabsLayersTooltipComponent
    @public
  */
  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    style: (0, _object.computed)('mousePosition', function () {
      const {
        y: top,
        x: left
      } = this.get('mousePosition');
      const offset = this.get('offset');
      return (0, _template.htmlSafe)(`
      top: ${top + offset}px; 
      left: ${left + offset}px; 
      pointer-events: none;
    `);
    }),
    /**
      Offset of tooltip div element in pixels.
      @argument offset
      @type Number
    */
    offset: 20,
    /**
      Top offset of tooltip div in pixels.
      @argument top
      @type Number
    */
    top: 0,
    /**
      Left offset of tooltip div in pixels.
      @argument left
      @type Number
    */
    left: 0,
    /**
      Native mousePosition object that is passed from labs-layers
      @type Object
      @private
    **/
    mousePosition: null,
    /**
      Geographic feature of the layer that is hovered when onLayerMouseMove is fired.
      @argument feature
      @type Object
      @private
    */
    feature: null,
    /**
      Layer that is hovered when onLayerMouseMove is fired.
      @argument layer
      @type Object
      @private
    */
    layer: null
  }));
  _exports.default = _default;
});