define("ember-mapbox-gl/templates/components/mapbox-gl-marker", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "5nxAGF3f",
    "block": "[[[40,[[[1,\"  \"],[18,1,[[28,[37,3],null,[[\"popup\"],[[50,\"mapbox-gl-popup\",0,null,[[\"marker\",\"MapboxGl\"],[[30,0,[\"marker\"]],[30,0,[\"MapboxGl\"]]]]]]]]]],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,1],[[30,0,[\"domContent\"]]],null]]],[\"&default\"],false,[\"in-element\",\"-in-el-null\",\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-mapbox-gl/templates/components/mapbox-gl-marker.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});