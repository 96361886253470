define("@nycplanning/ember/configuration", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getConfiguration;
  _exports.setConfiguration = setConfiguration;
  let configuration = {};
  function setConfiguration(settings) {
    configuration = settings;
  }
  function getConfiguration() {
    return configuration;
  }
});