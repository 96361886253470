define("@nycplanning/ember/components/labs-ui/icons/rectangle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <svg class="legend-icon-layer rectangle">
    <path d="M3 2L14 2L14 8L3 8Z" {{did-insert this.addAttributes}} />
  </svg>
  
  */
  {
    "id": "Mz6iLN/E",
    "block": "[[[10,\"svg\"],[14,0,\"legend-icon-layer rectangle\"],[12],[1,\"\\n  \"],[11,\"path\"],[24,\"d\",\"M3 2L14 2L14 8L3 8Z\"],[4,[38,0],[[30,0,[\"addAttributes\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"did-insert\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/icons/rectangle.hbs",
    "isStrictMode": false
  });
  let RectangleComponent = (_class = class RectangleComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "height", 10);
      _defineProperty(this, "width", 17);
      _defineProperty(this, "viewBox", '0 0 17 10');
      _defineProperty(this, "preserveAspectRatio", 'xMinYMid');
    }
    get options() {
      return this.args.options || {
        'stroke': 'SteelBlue',
        'fill': 'rgba(70, 130, 180, 0.5)',
        'stroke-linejoin': 'round'
      };
    }
    addAttributes(pathElement) {
      Object.entries(this.options).forEach(_ref2 => {
        let [attr, value] = _ref2;
        pathElement.setAttribute(attr, value);
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "addAttributes", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addAttributes"), _class.prototype)), _class);
  _exports.default = RectangleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RectangleComponent);
});