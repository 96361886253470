define("@nycplanning/ember/components/labs-ui/site-header-title", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  */
  {
    "id": "uACa5SWJ",
    "block": "[[[18,1,null]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/site-header-title.hbs",
    "isStrictMode": false
  });
  class SiteHeaderTitleComponent extends _component2.default {}
  _exports.default = SiteHeaderTitleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SiteHeaderTitleComponent);
});