define("@nycplanning/ember/components/labs-ui/icons/fa-icon", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/template"], function (_exports, _component, _templateFactory, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-inline-styles --}}
  <span class="legend-icon-layer" style={{this.spanStyle}}>
    <FaIcon
      @icon={{options.fa-icon}}
      @transform={{options.transform}}
      @prefix={{options.prefix}}
    />
  </span>
  
  */
  {
    "id": "mYUOO9Nh",
    "block": "[[[10,1],[14,0,\"legend-icon-layer\"],[15,5,[30,0,[\"spanStyle\"]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\",\"@transform\",\"@prefix\"],[[33,1,[\"fa-icon\"]],[33,1,[\"transform\"]],[33,1,[\"prefix\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"fa-icon\",\"options\"]]",
    "moduleName": "@nycplanning/ember/components/labs-ui/icons/fa-icon.hbs",
    "isStrictMode": false
  });
  class FaIconComponent extends _component2.default {
    get options() {
      return this.args.options || {};
    }
    get spanStyle() {
      return (0, _template.htmlSafe)(this.options.color ? `color: ${this.options.color}` : '');
    }
  }
  _exports.default = FaIconComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FaIconComponent);
});